import axios from "axios";

export const getAllBlogs = async () => {
  return await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/blog/get`,
  });
};

export const createBlogs = async (payload) => {
  return await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/blog`,
    data: payload,
  });
};

export const updateBlog = async (payload, blogId) => {
  return await axios({
    method: "PATCH",
    url: `${process.env.REACT_APP_API_URL}/blog/${blogId}`,
    data: payload,
  });
};

export const deleteBlog = async (blogId) => {
  return await axios({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_URL}/blog/${blogId}`,
  });
};

export const blogDetails = async (blogId) => {
  return await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/blog/get/${blogId}`,
  });
};
