import React, { useEffect, useState } from "react";
import { Button, Card } from "antd";
import DataTable from "../../components/smart/DataTable/DataTable";
import "./Users.scss";
import { Link } from "react-router-dom";
import ConfirmationModal from "../../components/presentational/ConfirmationModal/ConfirmationModal";
import { openNotification } from "../../components/smart/Notification";
import { deleteUser, getAllUser } from "../../services/userService";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");

  const getUsers = async () => {
    setLoading(true);
    const res = await getAllUser();
    setUsers(res?.data);
    setLoading(false);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const columns = [
    {
      title: "Blog Id",
      dataIndex: "_id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Actions",
      dataIndex: "details",
      key: "details",
      render: (_, record) => (
        <div className="actions-wrapper">
          <span
            className="delete-btn"
            onClick={() => {
              setSelectedUserId(record._id);
              setVisible(true);
            }}
          >
            Delete
          </span>
        </div>
      ),
    },
  ];

  const removeUser = async (blogId) => {
    try {
      await deleteUser(blogId);
      openNotification("Success", "User Deleted");
      setSelectedUserId("");
      setVisible(false);
      getUsers();
    } catch {
      openNotification("Oops!", "Unable to Delete BlUserog");
    }
  };

  return (
    <div className="users-Wrapper">
      <Card
        title={
          <div className="heading-wrapper">
            <div className="title">All Users</div>
          </div>
        }
      >
        <DataTable
          columns={columns}
          dataSource={users}
          loading={loading}
          pagination={true}
        />
      </Card>
      <ConfirmationModal
        onCancel={() => setVisible(false)}
        onOk={() => removeUser(selectedUserId)}
        visible={visible}
      />
    </div>
  );
};

export default Users;
