import React from "react";
import PropTypes from "prop-types";
import { Menu, Layout } from "antd";
import { userRoutes } from "../../../Routes/userRoutes";
import { Link } from "react-router-dom";
import { MenuFoldOutlined } from "@ant-design/icons";
import "./SideBar.scss";
import useWindowSize from "../../../utils/hooks";

// route.role.includes(user.role) &&
const { Sider } = Layout;
const SideBar = ({ collapsed, match, toggle }) => {
  const [browserWidth] = useWindowSize();
  const generateLinks = () => {
    const links = userRoutes.map((route, key) => {
      if (!route?.invisible) {
        return (
          <Menu.Item key={`${match.path}${route.path}`} icon={<route.icon />}>
            <Link to={`${match.path}${route.path}`}>
              <div className="sideBarItem">{route.name}</div>
            </Link>
          </Menu.Item>
        );
      }
    });
    return links;
  };

  return (
    <>
      {browserWidth < 620 ? (
        collapsed ? (
          <></>
        ) : (
          <Sider
            className="SideBar"
            trigger={null}
            collapsible
            collapsed={collapsed}
            style={{
              position: "fixed",
              top: 0,
              bottom: 0,
              overflow: "hidden",
              zIndex: 99,
            }}
          >
            {!collapsed ? (
              <div className="d_flex">
              <h3 className="logo">Otovoce</h3>
              <MenuFoldOutlined className="trigger" onClick={toggle} />
              </div>
            ) : (
                <h3 className="logo">Ot</h3>
            )}
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={[window.location.pathname]}
            >
              {generateLinks()}
            </Menu>
          </Sider>
        )
      ) : (
        <Sider
          className="SideBar"
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            overflow: "hidden",
          }}
        >
          {!collapsed ? (
            <h3 className="logo">Otovoce</h3>
          ) : (
            <h3 className="logo">OT</h3>
          )}
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[window.location.pathname]}
          >
            {generateLinks()}
          </Menu>
        </Sider>
      )}
    </>
  );
};

SideBar.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
};

export default SideBar;
