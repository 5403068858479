import { UserOutlined } from "@ant-design/icons";
import Blogs from "../pages/Blogs/Blogs";
import AddBlog from "../pages/AddBlog/AddBlog";
import Users from "../pages/Users/Users";
import Categories from "../pages/Categories/Categories";
import Topics from "../pages/Topics/Topics";
import Subscriptions from "../pages/Subscriptions/Subscriptions";

export const userRoutes = [
  {
    path: "/blogs",
    name: "Blogs",
    component: Blogs,
    icon: UserOutlined,
    role: [],
  },
  {
    invisible: true,
    path: "/add-blog",
    name: "Add Blogs",
    component: AddBlog,
    icon: UserOutlined,
    role: [],
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    icon: UserOutlined,
    role: [],
  },
  {
    path: "/categories",
    name: "Category",
    component: Categories,
    icon: UserOutlined,
    role: [],
  },
  {
    invisible: true,
    path: "/topics",
    name: "Topics",
    component: Topics,
    icon: UserOutlined,
    role: [],
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    component: Subscriptions,
    icon: UserOutlined,
    role: [],
  },
];
