import axios from "axios";

export const getAllPlans = async () => {
  return await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/api/stripe/plans/get`,
  });
};

export const createPlan = async (payload) => {
  return await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/api/stripe/plans`,
    data: payload,
  });
};

export const deletePlan = async (planId,planMongoId) => {
  return await axios({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_URL}/api/stripe/plans/${planMongoId}/${planId}`,
  });
};

export const getAllUserOfPlans = async (planId) => {
  return await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/api/stripe/plans/${planId}/users`,
  });
};
