import React, { useEffect, useState } from "react";
import { Button, Card, Radio } from "antd";
import DataTable from "../../components/smart/DataTable/DataTable";
import "./Topics.scss";
import ConfirmationModal from "../../components/presentational/ConfirmationModal/ConfirmationModal";
import { openNotification } from "../../components/smart/Notification";
import {
  deleteTopicOfCategory,
  topicsOfCategory,
} from "../../services/category";
import AddTopic from "./components/AddTopic/AddTopic";
import { useLocation } from "react-router";

const Topics = () => {
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [topicId, setTopicId] = useState("");
  const [mode, setMode] = useState("learn");

  const [selectedTopic, setSelectedTopic] = useState(null);

  const [openAddModal, setOpenAddModal] = useState(false);

  const query = new URLSearchParams(useLocation().search);

  const handleModeChange = (e) => {
    setMode(e.target.value);
  };

  const getTopics = async () => {
    setLoading(true);
    const res = await topicsOfCategory(query.get("categoryId"));
    setTopics(res?.data);
    setLoading(false);
  };

  useEffect(() => {
    getTopics();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Info",
      dataIndex: "info",
      key: "info",
    },
    {
      title: "Actions",
      dataIndex: "details",
      key: "details",
      render: (_, record) => (
        <div className="actions-wrapper">
          <span
            className="categoryDetails"
            onClick={() => {
              setSelectedTopic(record);
              setOpenAddModal(true);
            }}
          >
            Update
          </span>
          <span
            className="delete-btn"
            onClick={() => {
              setTopicId(record._id);
              setVisible(true);
            }}
          >
            Delete
          </span>
        </div>
      ),
    },
  ];

  const removeTopic = async (categoryId, topicId) => {
    try {
      await deleteTopicOfCategory(categoryId, topicId);
      openNotification("Success", "Topic Deleted");
      setTopicId("");
      setVisible(false);
      getTopics();
    } catch {
      openNotification("Oops!", "Unable to Delete Topic");
    }
  };

  const resetSelectedTopic = () => {
    setSelectedTopic(null);
  };

  return (
    <div className="topicsWrapper">
      <Radio.Group
        onChange={handleModeChange}
        value={mode}
        style={{
          marginBottom: 8,
        }}
      >
        <Radio.Button value="learn">Learn</Radio.Button>
        <Radio.Button value="test">Test</Radio.Button>
      </Radio.Group>
      <Card
        title={
          <div className="heading-wrapper">
            <div className="title">All Topics</div>
            <Button className="add-btn" onClick={() => setOpenAddModal(true)}>
              Add Topic
            </Button>
          </div>
        }
      >
        <DataTable
          columns={columns}
          dataSource={topics.filter((item) => item.mode == mode)}
          loading={loading}
          pagination={true}
        />
      </Card>
      <ConfirmationModal
        onCancel={() => setVisible(false)}
        onOk={() => removeTopic(query.get("categoryId"), topicId)}
        visible={visible}
      />
      <AddTopic
        setVisible={setOpenAddModal}
        visible={openAddModal}
        mode={mode}
        selectedTopic={selectedTopic}
        resetSelectedTopic={resetSelectedTopic}
      />
    </div>
  );
};

export default Topics;
