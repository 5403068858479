import axios from "axios";
import { apiConfig } from "../helpers/utils";

export const LoginAPI = async (userInfo) => {
    const body = {
      password: userInfo.password,
      email: userInfo.email,
    };
    return await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/login`,
      body,
      apiConfig
    );
  };