import AWS from "aws-sdk";

const S3_BUCKET = "otovoce";
const REGION = "eu-north-1";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY, // Ideally, generate signed URLs from the backend
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export const uploadFile = async (files) => {
  const keys = [];
  for (let file of files) {
    if (file?.originFileObj) {
      const fileKey = `${Date.now()}-${file.name}`; // Create a unique key using timestamp

      const params = {
        Body: file.originFileObj,
        Bucket: S3_BUCKET,
        Key: fileKey,
      };

      try {
        // Using the promise-based approach to handle the async nature
        await myBucket.putObject(params).promise();
        keys.push(fileKey);
      } catch (err) {
        throw err;
      }
    }
    keys.push(file?.name);
  }

  return keys; // Return keys after all uploads are complete
};
