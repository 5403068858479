import { Modal } from "antd";
import React from "react";

const ConfirmationModal = ({ onOk, onCancel, visible }) => {
  return (
    <Modal
      title={<span className="title">Are you sure You want to delete?</span>}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      closable
    ></Modal>
  );
};

export default ConfirmationModal;
