import React, { useEffect, useState } from "react";
import { Form, Input, Button, Modal, Upload } from "antd";
import Spin from "../../../../components/presentational/Spin";
import { openNotification } from "../../../../components/smart/Notification";
import { InboxOutlined } from "@ant-design/icons";
import { uploadFile } from "../../../../utils/uploadFile";
import "./AddTopic.scss";
import { addTopicInCategory } from "../../../../services/category";
import { useLocation } from "react-router";

const AddTopic = ({
  mode,
  visible,
  setVisible,
  selectedTopic,
  resetSelectedTopic,
}) => {
  const [loading, setLoading] = useState(false);

  const query = new URLSearchParams(useLocation().search);

  const getFileList = (files) => {
    const list = [];
    if (files) {
      for (let file of files) {
        list.push({
          name: file,
        });
      }
    }
    return list;
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const images = await uploadFile(values.images);
      const promptFile = await uploadFile(values.promptFile);
      await addTopicInCategory(
        {
          ...values,
          images,
          promptFile: promptFile?.[0],
          mode,
        },
        query.get("categoryId")
      );
      openNotification("Success", "Topic Created");
      setVisible(false);
    } catch (err) {
      console.log("err", err);
      openNotification("Oops", "Unable to Create Topic");
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    openNotification("Oops", "Kindly Fill All Fields");
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      closable
      className="add-topic-modal"
      onCancel={() => setVisible(false)}
      destroyOnClose={true}
      afterClose={() => resetSelectedTopic()}
    >
      <div className="add-category">
        <span className="heading">Add Topic</span>
        <Form
          name="basic"
          initialValues={{
            ...selectedTopic,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Info"
            name="info"
            rules={[{ required: true, message: "Please input Info!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: "Please input description!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Images">
            <Form.Item name="images" getValueFromEvent={normFile} noStyle>
              <Upload.Dragger
                name="files"
                valuePropName="fileList"
                multiple={true}
                accept=".png,.jpg.jpeg"
                action={() => ""}
                defaultFileList={getFileList(selectedTopic?.images ?? [])}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Supported File Types: .png, .jpg, .jpeg
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
          <Form.Item label="Prompt file">
            <Form.Item
              name="promptFile"
              getValueFromEvent={normFile}
              noStyle
              rules={[{ required: true, message: "Please upload prompt file" }]}
            >
              <Upload.Dragger
                name="files"
                accept=".js"
                action={() => ""}
                defaultFileList={getFileList(
                  selectedTopic?.promptFile
                    ? [selectedTopic?.promptFile]
                    : undefined
                )}
                multiple={true}
                maxCount={1}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">Supported File Types: .js</p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="submit-button">
              {loading ? <Spin /> : "Submit"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddTopic;
