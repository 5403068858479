import React, { useEffect, useState } from "react";
import { Button, Card } from "antd";
import DataTable from "../../components/smart/DataTable/DataTable";
import "./Blogs.scss";
import { Link } from "react-router-dom";
import { deleteBlog, getAllBlogs } from "../../services/bolgService";
import ConfirmationModal from "../../components/presentational/ConfirmationModal/ConfirmationModal";
import { openNotification } from "../../components/smart/Notification";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedBlogId, setSelectedBlogId] = useState("");

  const getBlogs = async () => {
    setLoading(true);
    const res = await getAllBlogs();
    setBlogs(res?.data);
    setLoading(false);
  };

  useEffect(() => {
    getBlogs();
  }, []);

  const columns = [
    {
      title: "Blog Id",
      dataIndex: "_id",
      key: "id",
    },
    {
      title: "Heading",
      dataIndex: "header",
      key: "header",
    },
    {
      title: "Sub Heading",
      dataIndex: "subHeading",
      key: "subHeading",
    },
    {
      title: "Actions",
      dataIndex: "details",
      key: "details",
      render: (_, record) => (
        <div className="actions-wrapper">
          <Link to={`/dashboard/add-blog?blogId=${record._id}`}>
            <span className="orderDetails">Update</span>
          </Link>
          <span
            className="delete-btn"
            onClick={() => {
              setSelectedBlogId(record._id);
              setVisible(true);
            }}
          >
            Delete
          </span>
        </div>
      ),
    },
  ];

  const removeBlog = async (blogId) => {
    try {
      await deleteBlog(blogId);
      openNotification("Success", "Blog Deleted");
      setSelectedBlogId("");
      setVisible(false);
      getBlogs();
    } catch {
      openNotification("Oops!", "Unable to Delete Blog");
    }
  };

  return (
    <div className="ordersWrapper">
      <Card
        title={
          <div className="heading-wrapper">
            <div className="title">All Blogs</div>
            <Link to={"/dashboard/add-blog"}>
              <Button className="add-btn">Add Blog</Button>
            </Link>
          </div>
        }
      >
        <DataTable
          columns={columns}
          dataSource={blogs}
          loading={loading}
          pagination={true}
        />
      </Card>
      <ConfirmationModal
        onCancel={() => setVisible(false)}
        onOk={() => removeBlog(selectedBlogId)}
        visible={visible}
      />
    </div>
  );
};

export default Blogs;