import axios from "axios";

export const getAllCategories = async () => {
  return await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/categories/get`,
  });
};

export const createCategories = async (payload) => {
  return await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/categories`,
    data: payload,
  });
};

export const addTopicInCategory = async (payload, categoryId) => {
  return await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/categories/${categoryId}/topics`,
    data: payload,
  });
};

export const deleteCategory = async (categoryId) => {
  return await axios({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_URL}/categories/${categoryId}`,
  });
};

export const categoryDetails = async (categoryId) => {
  return await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/categories/get/${categoryId}`,
  });
};

export const topicsOfCategory = async (categoryId) => {
  return await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/categories/category/${categoryId}/topics`,
  });
};

export const deleteTopicOfCategory = async (categoryId,topicId) => {
  return await axios({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_URL}/categories/category/${categoryId}/topic/${topicId}`,
  });
};