import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from '../pages/Auth/Login'

const AuthRoutes = () => {
  return (
    <Switch>
      <Route path="/login" exact component={Login} />
      <Redirect from="/" to ="/login" />
    </Switch>
  );
};
export default AuthRoutes;
