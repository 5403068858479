import React, { useEffect, useState } from "react";
import { Button, Card, Radio } from "antd";
import DataTable from "../../components/smart/DataTable/DataTable";
import "./Categories.scss";
import { Link } from "react-router-dom";
import ConfirmationModal from "../../components/presentational/ConfirmationModal/ConfirmationModal";
import { openNotification } from "../../components/smart/Notification";
import AddCategory from "./components/AddCategory/AddCategory";
import { deleteCategory, getAllCategories } from "../../services/category";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");

  const [openAddModal, setOpenAddModal] = useState(false);

  const getBlogs = async () => {
    setLoading(true);
    const res = await getAllCategories();
    setCategories(res?.data);
    setLoading(false);
  };

  useEffect(() => {
    getBlogs();
  }, []);

  const columns = [
    {
      title: "Category Id",
      dataIndex: "_id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Actions",
      dataIndex: "details",
      key: "details",
      render: (_, record) => (
        <div className="actions-wrapper">
          <Link to={`/dashboard/topics?categoryId=${record._id}`}>
            <span className="categoryDetails">Details</span>
          </Link>
          <span
            className="delete-btn"
            onClick={() => {
              setSelectedCategoryId(record._id);
              setVisible(true);
            }}
          >
            Delete
          </span>
        </div>
      ),
    },
  ];

  const removeBlog = async (categoryId) => {
    try {
      await deleteCategory(categoryId);
      openNotification("Success", "Category Deleted");
      setSelectedCategoryId("");
      setVisible(false);
      getBlogs();
    } catch {
      openNotification("Oops!", "Unable to Delete Category");
    }
  };

  return (
    <div className="categoriesWrapper">

      <Card
        title={
          <div className="heading-wrapper">
            <div className="title">All Categories</div>
            <Button className="add-btn" onClick={() => setOpenAddModal(true)}>
              Add Category
            </Button>
          </div>
        }
      >
        <DataTable
          columns={columns}
          dataSource={categories}
          loading={loading}
          pagination={true}
        />
      </Card>
      <ConfirmationModal
        onCancel={() => setVisible(false)}
        onOk={() => removeBlog(selectedCategoryId)}
        visible={visible}
      />
      <AddCategory
        setVisible={setOpenAddModal}
        visible={openAddModal}
      />
    </div>
  );
};

export default Categories;
