import React, { useState } from "react";
import { Form, Input, Button, Modal, Select } from "antd";
import Spin from "../../../../components/presentational/Spin";
import { openNotification } from "../../../../components/smart/Notification";
import "./AddPlan.scss";
import { createPlan } from "../../../../services/stripe";

const AddPlan = ({ visible, setVisible }) => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      await createPlan({
        ...values,
      });
      openNotification("Success", "Plan Created");
      setVisible(false);
    } catch (err) {
      console.log("err", err);
      openNotification("Oops", "Unable to Create Plan");
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    openNotification("Oops", "Kindly Fill All Fields");
  };

  const currencies = [
    { label: "US Dollar (USD)", value: "usd" },
    { label: "Euro (EUR)", value: "eur" },
    { label: "British Pound (GBP)", value: "gbp" },
    { label: "Japanese Yen (JPY)", value: "jpy" },
    { label: "Canadian Dollar (CAD)", value: "cad" },
    { label: "Australian Dollar (AUD)", value: "aud" },
  ];

  const interval = [
    { label: "Yearly", value: "year" },
    { label: "Monthly", value: "month" },
    { label: "Weekly", value: "week" },
    { label: "Daily", value: "day" },
  ];

  return (
    <Modal
      visible={visible}
      footer={null}
      closable
      className="add-plan-modal"
      onCancel={() => setVisible(false)}
      destroyOnClose={true}
    >
      <div className="add-category">
        <span className="heading">Create Plan</span>
        <Form
          name="basic"
          initialValues={{}}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Name"
            name="productName"
            rules={[{ required: true, message: "Please input name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Price"
            name="amount"
            rules={[{ required: true, message: "Please input Price!" }]}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            label="Simulations Allowed"
            name="allowedSimulationCount"
            rules={[{ required: true, message: "Please input Simulations Allowed!" }]}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            label="Currency"
            name="currency"
            rules={[{ required: true, message: "Please select Currency!" }]}
          >
            <Select>
              {currencies.map((item) => {
                return (
                  <Select.Option value={item.value} key={item.value}>
                    {item.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label="Plan Interval"
            name="interval"
            rules={[{ required: true, message: "Please select Currency!" }]}
          >
            <Select>
              {interval.map((item) => {
                return (
                  <Select.Option value={item.value} key={item.value}>
                    {item.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="submit-button">
              {loading ? <Spin /> : "Submit"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddPlan;
