import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { Layout } from "antd";
import TopBar from "../components/smart/TopBar/TopBar";
import SideBar from "../components/smart/SiderBar/SideBar";
import { userRoutes } from "../Routes/userRoutes";
import { useDispatch } from "react-redux";
import "./AdminLayout.scss";
import useWindowSize from "../utils/hooks";
const AdminLayout = ({ match }) => {
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();
  const toggle = () => {
    setCollapsed((prevState) => !prevState);
  };
  const [browserWidth] = useWindowSize();

  const generateRoutes = () => {
    let admin = userRoutes.map((route, key) => {
      if (true) {
        return (
          <Route
            path={`${match.path}${route.path}`}
            key={key}
            component={route.component}
            exact
          />
        );
      }
    });
    return admin;
  };

  useEffect(() => {
    if (browserWidth < 620) {
      setCollapsed(true);
    }
    else{
      setCollapsed(false);
    }
  }, [browserWidth]);

  return (
    <Layout className="DashBoardLayout">
      <SideBar match={match} collapsed={collapsed} toggle={toggle} />
      <Layout
        className="site-layout"
        style={
          browserWidth < 620
            ? {}
            : !collapsed
            ? { marginLeft: "200px" }
            : { marginLeft: "80px" }
        }
      >
        <TopBar toggle={toggle} collapse={collapsed} />
        <div className="main-content">
          <Switch>{generateRoutes()}</Switch>
        </div>
      </Layout>
    </Layout>
  );
};
AdminLayout.propTypes = {
  match: PropTypes.object.isRequired,
};
export default AdminLayout;
