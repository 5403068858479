import React, { useState } from "react";
import { Form, Input, Button, Modal } from "antd";
import "./AddCategory.scss";
import { createCategories } from "../../../../services/category";
import Spin from "../../../../components/presentational/Spin";
import { openNotification } from "../../../../components/smart/Notification";

const AddCategory = ({ visible, setVisible }) => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      await createCategories({
        ...values,
      });
      openNotification("Success", "Category Created");
      setVisible(false);
    } catch {
      openNotification("Oops", "Unable to Create Blog");
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    openNotification("Oops", "Kindly Fill All Fields");
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      closable
      className="add-category-modal"
      onCancel={() => setVisible(false)}
    >
      <div className="add-category">
        <span className="heading">Add Category</span>
        <Form
          name="basic"
          initialValues={{}}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input Heading!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="add-category-btn">
              {loading ? <Spin /> : "Submit"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddCategory;
