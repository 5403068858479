import React, { useEffect, useState } from "react";
import { Form, Input, Button, Switch, Upload } from "antd";
import "./AddBlog.scss";
import TagInput from "../../components/smart/TagInput/TagInput";
import {
  blogDetails,
  createBlogs,
  updateBlog,
} from "../../services/bolgService";
import { useLocation } from "react-router-dom";
import { openNotification } from "../../components/smart/Notification";
import { InboxOutlined } from "@ant-design/icons";
import { uploadFile } from "../../utils/uploadFile";

const AddBlog = ({ history }) => {
  const [links, setLinks] = useState([]);
  const [blogData, setBlogData] = useState({});
  const [loading, setLoading] = useState(false);

  const query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    getBlogDetails(query.get("blogId"));
  }, []);

  const getFileList = (files) => {
    const list = [];
    if (files) {
      for (let file of files) {
        list.push({
          name: file,
        });
      }
    }
    return list;
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onFinish = async (values) => {
    try {
      if (query.get("blogId")) {
        await updateBlog(
          {
            ...values,
            links: links,
          },
          query.get("blogId")
        );
        openNotification("Success", "Blog Updated");
      } else {
        const image = await uploadFile(values.image);
        await createBlogs({
          ...values,
          links: links,
          image:image?.[0]
        });
        openNotification("Success", "Blog Created");
      }
      history.push("/dashboard/blogs");
    } catch {
      openNotification("Oops", "Unable to Create Blog");
    }
  };

  const onFinishFailed = (errorInfo) => {
    openNotification("Oops", "Kindly Fill All Fields");
  };

  const getBlogDetails = async (blogId) => {
    if (blogId) {
      setLoading(true);
      try {
        const blog = await blogDetails(blogId);
        setBlogData(blog.data);
        setLinks(blog.data?.links);
      } catch {
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {!loading && (
        <div className="add-blog">
          <span className="heading">Add/Update Blog</span>
          <Form
            name="basic"
            initialValues={{
              ...blogData,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label="Heading"
              name="header"
              rules={[{ required: true, message: "Please input Heading!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Sub  Heading"
              name="subHeading"
              rules={[{ required: true, message: "Please input Sub Heading!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Introduction"
              name="intro"
              rules={[
                { required: true, message: "Please input Introduction!" },
              ]}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              label="Body"
              name="body"
              rules={[{ required: true, message: "Please input Body!" }]}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              label="Conclusion"
              name="conclusion"
              rules={[{ required: true, message: "Please input Conclusion!" }]}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item label="Featured" valuePropName="checked" name="isFeatured">
              <Switch />
            </Form.Item>

            <Form.Item label="Links">
              <TagInput links={links} setLinks={setLinks} />
            </Form.Item>

            <Form.Item label="Prompt file">
            <Form.Item
              name="image"
              getValueFromEvent={normFile}
              noStyle
              rules={[{ required: true, message: "Please upload prompt file" }]}
            >
              <Upload.Dragger
                name="files"
                accept=".png,.jpg.jpeg"
                action={() => ""}
                // defaultFileList={getFile(
                //   selectedTopic?.image
                //     ? [selectedTopic?.promptFile]
                //     : undefined
                // )}
                multiple={true}
                maxCount={1}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">Supported File Types: .js</p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </>
  );
};

export default AddBlog;
