import axios from "axios";

export const getAllUser = async () => {
  return await axios({
    method: "POSt",
    url: `${process.env.REACT_APP_API_URL}/users/get`,
  });
};

export const deleteUser = async (userId) => {
  return await axios({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_URL}/users/${userId}`,
  });
};
