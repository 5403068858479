import React, { useState, useEffect } from "react";
import { Switch } from "react-router-dom";
import AuthRouter from "./HOC/AuthRouter";
import PrivateRouter from "./HOC/PrivateRouter";
import "antd/dist/antd.min.css";
import "./App.scss";
import { useDispatch } from "react-redux";
import {
  LoginFail,
  LoginSuccess,
  removeProfile,
  setProfile,
} from "./store/auth/authActions";
import AuthRoutes from "./Routes/AuthRoutes";
import AdminLayout from "./layout/AdminLayout";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUserData = () => {
      const user = localStorage.getItem("user");
      if (user) {
        dispatch(LoginSuccess());
        dispatch(setProfile(JSON.parse(user)));
      } else {
        localStorage.removeItem("token");
        dispatch(LoginFail());
        dispatch(removeProfile());
      }
    };
    fetchUserData();
  }, []);

  return (
    <Switch>
      <PrivateRouter path="/dashboard" component={AdminLayout} />
      <AuthRouter path="/" component={AuthRoutes} />
    </Switch>
  );
}

export default App;
