import React, { useEffect, useState } from "react";
import { Button, Card } from "antd";
import { openNotification } from "../../components/smart/Notification";
import { deletePlan, getAllPlans } from "../../services/stripe";
import DataTable from "../../components/smart/DataTable/DataTable";
import { Link } from "react-router-dom";
import "./Subscriptions.scss";
import AddPlan from "./components/AddPlan/AddPlan";
import ConfirmationModal from "../../components/presentational/ConfirmationModal/ConfirmationModal";

const Subscriptions = () => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [planMongoId, setPlanMongoId] = useState("");
  const [planId, setPlanId] = useState("");

  const [openAddModal, setOpenAddModal] = useState(false);

  const getSubscription = async () => {
    setLoading(true);
    const res = await getAllPlans();
    setPlans(res?.data);
    setLoading(false);
  };

  useEffect(() => {
    getSubscription();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "planName",
      key: "planName",
    },
    {
      title: "Amount",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Recurring Period",
      dataIndex: "recurring",
      key: "recurring",
    },
    {
      title: "Actions",
      dataIndex: "details",
      key: "details",
      render: (_, record) => (
        <div className="actions-wrapper">
          <Link to={"#"}>
            <span className="categoryDetails">Customers</span>
          </Link>
          <span
            className="delete-btn"
            onClick={() => {
              setPlanId(record.id);
              // setVisible(true);
            }}
          >
            Update
          </span>
          <span
            className="delete-btn"
            onClick={() => {
              setPlanMongoId(record._id);
              setPlanId(record.planId);
              setVisible(true);
            }}
          >
            Delete
          </span>
        </div>
      ),
    },
  ];

  const removePlan = async (planId,planMongoId) => {
    try {
      await deletePlan(planId,planMongoId);
      openNotification("Success", "Plan Deleted");
      setPlanId("");
      setVisible(false);
      getSubscription();
    } catch {
      openNotification("Oops!", "Unable to Delete Plan");
    }
  };

  return (
    <div className="topicsWrapper">
      <Card
        title={
          <div className="heading-wrapper">
            <div className="title">All Subscription Plan</div>
            <Button className="add-btn" onClick={() => setOpenAddModal(true)}>
              Create Subscription Plan
            </Button>
          </div>
        }
      >
        <DataTable
          columns={columns}
          dataSource={plans}
          loading={loading}
          pagination={true}
        />
      </Card>
      <ConfirmationModal
        onCancel={() => setVisible(false)}
        onOk={() => removePlan(planId,planMongoId)}
        visible={visible}
      />
      <AddPlan setVisible={setOpenAddModal} visible={openAddModal} />
    </div>
  );
};

export default Subscriptions;
