import React from "react";
import PropTypes from "prop-types";
import { Layout, Button } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ShopFilled,
  LoginOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import "./TopBar.scss";
import { Logout } from "../../../store/auth/authActions";
import useWindowSize from "../../../utils/hooks";
const { Header } = Layout;
const TopBar = ({ toggle, collapse }) => {
  const dispatch = useDispatch();
  const [browserWidth] = useWindowSize();
  const user = useSelector((state) => state?.auth?.user);
  console.log("user",user)
  return (
    <Header className="site-layout-background">
      <div style={{ display: "flex", alignItems: "center" }}>
        {collapse ? (
          <MenuUnfoldOutlined className="trigger" onClick={toggle} />
        ) : browserWidth < 620 ? (
          <></>
        ) : (
          <MenuFoldOutlined className="trigger" onClick={toggle} />
        )}
      </div>
      <div className="USERLOGO">
        <p className="UserName">{`${user?.username}`} </p>
        <div>
          <img
            src="https://www.w3schools.com/howto/img_avatar.png"
            alt="Avatar"
            className="profileImage"
          />
        </div>
        {/* <span type="primary" > */}
        <LoginOutlined onClick={() => dispatch(Logout())} />
        {/* </span> */}
      </div>
    </Header>
  );
};

TopBar.propTypes = {
  collapse: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};
export default TopBar;
