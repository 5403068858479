import React, { useState } from "react";
import AUTH_ICON from "../../assets/images/auth_icon.svg";
import { Input, Form, Button } from "antd";
import Spin from "../../components/presentational/Spin";
import { useDispatch } from "react-redux";
import { LoginAPI } from "../../services/authServices";
import { LoginSuccess, setProfile } from "../../store/auth/authActions";
import { openNotification } from "../../components/smart/Notification";
import "./Login.scss";

const Login = ({history}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const onFinish = async (values) => {
    setLoading(true);
    try {
      const userData = {
        email: values.email,
        password: values.password,
      };
      const { data } = await LoginAPI(userData);
      setLoading(false);
      localStorage.setItem("user", JSON.stringify(data));
      dispatch(LoginSuccess());
      dispatch(setProfile(data));
      openNotification("Login Successfully", "Welcome to the app");
      history.push('/dashboard/blogs')
    } catch (error) {
      setLoading(false);
      openNotification("Oops", error?.response?.data?.message);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="mainWrapper">
      <div className="loginFormWrapper main_page_width">
        <div className="loginForm">
          <div className="form">
            <span className="formHeadingWrapper">
              <span className="loginAcc">Login with your account</span>
              <span className="loginIcon">
                <img src={AUTH_ICON} alt={"AUTH_ICON"} />
              </span>
            </span>
            <Form
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="on"
            >
              <Form.Item
                name="email"
                label="Email"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item
                name="password"
                label="Password"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {loading ? <Spin /> : "Login"}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
