import { Table } from 'antd'
import React from 'react'

const DataTable = ({loading,columns,dataSource,pagination}) => {
  return (
    <Table
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
        loading={loading}
      />
  )
}

export default DataTable